import About from "./about/About";
import Gallery from "./gallery/Gallery";
import Contact from "./contact/Contact";
import Exhibition from "./exhibition/Exhibition";
import Header from "./header/Header";

const Home = () => {
	return (
		<>
			<Header />
			<About />
			<Exhibition />
			<Gallery />
			<Contact />
		</>
	);
};

export default Home;
