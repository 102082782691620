export type TImageData = {
	id: number,
	title: string,
	description: string,
	size: string,
	date: string
}

const galleryData: TImageData[] = [
	{id: 1, title: "Ember madárral", description: "olaj, karton", size: "28x12", date: "2001"},
	{id: 2, title: "Az élet fanyomai", description: "vegyes technika, vászon", size: "70x50", date: "2018"},
	{id: 3, title: "Zárás", description: "vegyes technika, vászon", size: "40x60", date: "2010"},
	{id: 4, title: "A kereszt fényei", description: "olaj, vászon", size: "90x60", date: ""},
	{id: 5, title: "A lélek útja", description: "olaj, farost", size: "210x240", date: "2017"},
	{id: 6, title: "Kék", description: "pasztell, karton", size: "70x30", date: "2023"},
	{id: 7, title: "Lélekhajó", description: "vegyes technika, vászon", size: "70x50", date: "2018"},
	{id: 8, title: "A lélek ösvényei", description: "vegyes technika, vászon", size: "60x40", date: "2018"},
	{id: 9, title: "Ölelés", description: "vegyes technika", size: "70x30", date: "2023"},
	{id: 10, title: "Álom", description: "fametszet", size: "25x40", date: "2022"},
	{id: 11, title: "Álom", description: "pasztell, karton", size: "70x30", date: "2023"},
	{id: 12, title: "Álom 2", description: "fametszet", size: "25x40", date: "2022"},
	{id: 13, title: "13 próféta 1", description: "akril, papír", size: "50x13", date: "2016"},
	{id: 14, title: "Madárijesztő", description: "fametszet, papír", size: "25x40", date: "2017"},
	{id: 15, title: "Tanu", description: "vegyes technika, vászon", size: "120x50", date: "2023"},
	{id: 16, title: "Angyal", description: "akril, farost", size: "80x60", date: ""},
	{id: 17, title: "Kék angyal", description: "akril, vászon", size: "60x30", date: "2018"},
	{id: 18, title: "Árnyékban", description: "akril, farost", size: "60x30", date: ""},
	{id: 19, title: "A tudás fája 3", description: "pasztel, papír", size: "", date: "2019"},
	{id: 20, title: "Érzelmek", description: "fametszet, papír", size: "297x210", date: ""},
	{id: 21, title: "Hullám", description: "akril, karton", size: "21x30", date: "2023"},
	{id: 22, title: "Látomás", description: "akril, karton", size: "70x40", date: "2020"},
	{id: 23, title: "Metamorfózis", description: "olaj, vászon", size: "160x80", date: ""},
	{id: 24, title: "Naplemente", description: "pasztel, papír", size: "60x45", date: ""},
	{id: 25, title: "Próféta 1", description: "akril, karton", size: "32x8", date: "2018"},
	{id: 26, title: "Próféta 3", description: "akril, karton", size: "13x49,5", date: "2018"},
	{id: 27, title: "Sugallat", description: "akril, farost", size: "50x50", date: "2023"},
	{id: 28, title: "Szabadulási kísérlet", description: "olaj, farost", size: "40x30", date: "2021"},
	{id: 29, title: "Szent", description: "akril, karton", size: "49,5x13", date: "2018"},
	{id: 30, title: "Sziklák", description: "akril, vászon", size: "50x40", date: "2023"},
	{id: 31, title: "Találkozás", description: "hidegtű, papír", size: "30x50", date: ""},
	{id: 32, title: "Tánc", description: "akril, vászon", size: "120x50", date: "2019"},
	{id: 33, title: "Táncos", description: "akril, vászon", size: "160x60", date: ""},
	{id: 34, title: "Tudás fája 2", description: "pasztel, papír", size: "60x40", date: ""},
	{id: 35, title: "Világtengely 1", description: "vegyes technika", size: "160x60", date: "2022"},
	{id: 36, title: "XII", description: "olaj, vászon, arany", size: "70x50", date: ""},
	{id: 37, title: "Pünkösdi jelenet", description: "olaj, vászon, arany", size: "240x200", date: ""},
	{id: 38, title: "Szent László", description: "olaj, vászon, arany", size: "200x150", date: ""},
];

const earthData: TImageData[] = [
	{
		id: 1,
		title: "A keresztények figyelmeztetése",
		description: "mélyníomás, papír, aranyfüst",
		size: "70x70",
		date: ""
	},
	{
		id: 2,
		title: "A keresztények figyelmeztetése 2",
		description: "vegyes technika, papír",
		size: "100x70",
		date: ""
	},
	{
		id: 3,
		title: "Az első négy harsonaszó",
		description: "vegyes technika, papír",
		size: "70x100",
		date: ""
	},
	{
		id: 4,
		title: "Az ötödik harsonaszó",
		description: "vegyes technika, papír",
		size: "70x100",
		date: ""
	},
	{
		id: 5,
		title: "Csillagkapu 1",
		description: "litográfia, papír",
		size: "70x100",
		date: ""
	},
	{
		id: 6,
		title: "Égi lyuk",
		description: "mélyníomás, papír",
		size: "30x30",
		date: ""
	},
	{
		id: 7,
		title: "Együttállás",
		description: "mélynyomás, papír",
		size: "21x21",
		date: ""
	},
	{
		id: 8,
		title: "Együttállások",
		description: "mélynyomás, papír",
		size: "30x30",
		date: ""
	},
	{
		id: 9,
		title: "Jóslat",
		description: "vegyes technika, papír",
		size: "70x100",
		date: ""
	},
	{
		id: 10,
		title: "Nyitott tér 1.",
		description: "vegyes technika",
		size: "100x90",
		date: "2020"
	},
	{
		id: 11,
		title: "Nyitott tér 2.",
		description: "vegyes technika",
		size: "100x90",
		date: ""
	},
	{
		id: 12,
		title: "Prófécia 1",
		description: "vegyes technika, papír",
		size: "",
		date: "2024"
	},
	{
		id: 13,
		title: "Prófécia 2",
		description: "vegyes technika, papír",
		size: "",
		date: "2024"
	},
	{
		id: 14,
		title: "Prófécia 3",
		description: "vegyes technika, papír",
		size: "",
		date: "2024"
	},
];

const stationsData: TImageData[] = [
	{id: 1, title: "I", description: "Stációk (Alsóőr)", size: "", date: ""},
	{id: 2, title: "II", description: "Stációk (Alsóőr)", size: "", date: ""},
	{id: 3, title: "III", description: "Stációk (Alsóőr)", size: "", date: ""},
	{id: 4, title: "IV", description: "Stációk (Alsóőr)", size: "", date: ""},
	{id: 5, title: "V", description: "Stációk (Alsóőr)", size: "", date: ""},
	{id: 6, title: "VI", description: "Stációk (Alsóőr)", size: "", date: ""},
	{id: 7, title: "VII", description: "Stációk (Alsóőr)", size: "", date: ""},
	{id: 8, title: "VIII", description: "Stációk (Alsóőr)", size: "", date: ""},
	{id: 9, title: "IX", description: "Stációk (Alsóőr)", size: "", date: ""},
	{id: 10, title: "X", description: "Stációk (Alsóőr)", size: "", date: ""},
	{id: 11, title: "XI", description: "Stációk (Alsóőr)", size: "", date: ""},
	{id: 12, title: "XII", description: "Stációk (Alsóőr)", size: "", date: ""},
	{id: 13, title: "XIII", description: "Stációk (Alsóőr)", size: "", date: ""},
	{id: 14, title: "XIV", description: "Stációk (Alsóőr)", size: "", date: ""},
];

const wavesData: TImageData[] = [
	{id: 1, title: "I", description: "diófapác, papír", size: "70x50", date: ""},
	{id: 2, title: "II", description: "diófapác, papír", size: "70x50", date: ""},
	{id: 3, title: "III", description: "diófapác, papír", size: "70x50", date: ""},
	{id: 4, title: "IV", description: "diófapác, papír", size: "70x50", date: ""},
	{id: 5, title: "V", description: "diófapác, papír", size: "70x50", date: ""},
	{id: 6, title: "VI", description: "diófapác, papír", size: "70x50", date: ""},
	{id: 7, title: "VII", description: "diófapác, papír", size: "70x50", date: ""},
	{id: 8, title: "VIII", description: "diófapác, papír", size: "70x50", date: ""},
	{id: 9, title: "IX", description: "diófapác, papír", size: "70x50", date: ""},
	{id: 10, title: "X", description: "diófapác, papír", size: "70x50", date: ""},
	{id: 11, title: "XI", description: "diófapác, papír", size: "70x50", date: ""},
	{id: 12, title: "XII", description: "diófapác, papír", size: "70x50", date: ""},
	{id: 13, title: "XIII", description: "diófapác, papír", size: "70x50", date: ""},
	{id: 14, title: "XIV", description: "diófapác, papír", size: "70x50", date: ""},
	{id: 15, title: "XV", description: "diófapác, papír", size: "70x50", date: ""},
	{id: 16, title: "XVI", description: "diófapác, papír", size: "70x50", date: ""},
	{id: 17, title: "XVII", description: "diófapác, papír", size: "70x50", date: ""},
	{id: 18, title: "XVIII", description: "diófapác, papír", size: "70x50", date: ""},
	{id: 19, title: "XIX", description: "diófapác, papír", size: "70x50", date: ""},
	{id: 20, title: "XX", description: "diófapác, papír", size: "70x50", date: ""},
	{id: 21, title: "XXI", description: "diófapác, papír", size: "70x50", date: ""},
];

export type TGalleryFolders = "gallery" | "stations" | "waves" | "earth";

export const mediaData: Record<TGalleryFolders, TImageData[]> = {
	gallery: galleryData,
	earth: earthData,
	waves: wavesData,
	stations: stationsData,
}