import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import theme from "./style/theme";

import Navbar from "./pages/components/navigation/Navbar";
import Home from "./pages/home/Home";
import Footer from "./pages/components/footer/Footer";



function App() {
	return (
		<BrowserRouter>
			<ThemeProvider theme={theme}>
				<Navbar />
				<Routes>
					<Route path="" element={<Home />} />
				</Routes>
				<Footer />
			</ThemeProvider>
		</BrowserRouter>
	);
}

export default App;
