import {Dispatch, ReactElement, SetStateAction} from "react";
import {TImageData} from "../../../data/mediaData";

type TGalleryItemProps = {
	selectedFolder: string;
	imageData: TImageData;
	setSelectedImage: Dispatch<SetStateAction<number | null>>;
}

const GalleryItem: (props: TGalleryItemProps) => ReactElement = ({selectedFolder, imageData: {id}, setSelectedImage}) =>
{
	return (
		<div key={id}>
			<img
				onClick={() => setSelectedImage(id)}
				style={
					{
						height: "500px",
						backgroundSize: "cover",
						backgroundImage: `url(assets/media/${selectedFolder}/${selectedFolder}${id.toString().padStart(3, "0")}.jpg)`,
						backgroundPosition: "center",
					}
				}
			/>
		</ div>
	)
}

export default GalleryItem;