import {TImageData} from "../../../data/mediaData";
import {ReactElement} from "react";
import "./GalleryModal.scss";
import {Button} from "@mui/material";
import {HighlightOff} from "@mui/icons-material";

type TGalleryModalProps = {
	selectedFolder: string;
	imageData: TImageData;
	onClick: () => void;
}

const GalleryModal: (props: TGalleryModalProps) => ReactElement = ({selectedFolder, imageData, onClick}) =>
{
	return (
		<div id="gallery-modal" onClick={onClick}>
			<img
				src={`assets/media/${selectedFolder}/${selectedFolder}${imageData.id.toString().padStart(3, "0")}.jpg`}
				alt={"artwork"}
			/>
			<div className={"text-container"}>
			<h3>{`${imageData.title}`}</h3>
			<p>{`${imageData.description}`}</p>
			<p>{imageData.size.length > 0 ? `${imageData.size} cm` : null}</p>
			<p>{`${imageData.date}`}</p>
			</div>
			<Button
				variant="text"
				color="primary"
				size="medium"
				sx={{
					fontSize: "3em",
				}}
				onClick={onClick}
				className={"close-button"}
			><HighlightOff sx={{fontSize: "inherit"}}/>
			</Button>
		</div>
	);
}

export default GalleryModal;