import "./GoogleMap.scss";

const GoogleMap = () =>
{
	return (
		<iframe
			id={"google-map"}
			title="google-maps"
			src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d445656.0911261975!2d17.447821512115862!3d47.49138856340881!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476bcf8cf6d1eabb%3A0x23e5220cbbf9ea6f!2sRavazd%2C%209091!5e1!3m2!1sen!2shu!4v1740517245362!5m2!1sen!2shu"
			style={{border: "0"}}
			allowFullScreen
			loading="lazy"
			referrerPolicy="no-referrer-when-downgrade"
		></iframe>
	);
};

export default GoogleMap;
