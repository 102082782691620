import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import {useTranslation} from "react-i18next";

import "./Gallery.scss";
import GalleryItem from "./GalleryItem";
import {mediaData, TGalleryFolders} from "../../../data/mediaData";
import {useState} from "react";
import GalleryModal from "./GalleryModal";
import {Button} from "@mui/material";

const Gallery = () =>
{
	const [selectedImage, setSelectedImage] = useState<number | null>(null);
	const [selectedFolder, setSelectedFolder] = useState<TGalleryFolders>("gallery");
	const {t} = useTranslation();

	const responsive = {
		desktop: {
			breakpoint: {max: 3000, min: 1200},
			items: 3,
			slidesToSlide: 1,
		},
		tablet: {
			breakpoint: {max: 1199, min: 800},
			items: 2,
			slidesToSlide: 1,
		},
		mobile: {
			breakpoint: {max: 799, min: 0},
			items: 1,
			slidesToSlide: 1,
		},
	};

	return (
		<section id="gallery">
			<h2>{t(`gallery.title`)}</h2>
			<div className="gallery-select">
				{Object.keys(mediaData).map((folder: string) =>
					<Button
						variant="text"
						size="small"
						sx={{
							fontSize: "20px",
						}}
						key={folder}
						onClick={() => setSelectedFolder(folder as TGalleryFolders)}
						className={selectedFolder === folder ? "selected" : ""}
					>
						{t(`gallery.${folder}`)}
					</Button>
				)}
			</div>

			<Carousel
				swipeable={true}
				draggable={false}
				showDots={false}
				responsive={responsive}
				infinite={true}
				autoPlay={selectedImage === null}
				autoPlaySpeed={5000}
				keyBoardControl={true}
				customTransition="transform 1000ms ease-in-out"
				transitionDuration={1000}
				containerClass="carousel-container"
				itemClass="carousel-item"
				removeArrowOnDeviceType={[]}
				renderDotsOutside={false}
				centerMode={false}
			>
				{mediaData[selectedFolder].map(imageData => GalleryItem({selectedFolder, imageData, setSelectedImage}))}
			</Carousel>
			{selectedImage !== null &&
				<GalleryModal selectedFolder={selectedFolder} imageData={mediaData[selectedFolder][selectedImage - 1]}
							  onClick={() => setSelectedImage(null)}/>
			}
		</section>
	);
};

export default Gallery;
