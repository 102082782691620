import {useTranslation} from "react-i18next";
import "./Exhibition.scss";
import profilePic from "../../../media/pic/exhibition.jpg";

const Exhibition = () =>
{
	const {t} = useTranslation();

	return (
		<section id="exhibition">
			<div className="text-container">
				<h2>{t('exhibition.title')}</h2>
				{t("exhibition.content")
					.split("\n")
					.map(t => (
						<p key={t}>{t}</p>
					))
				}
			</div>
			<div
				className="img-container"
				style={{backgroundImage: `url(${profilePic})`}}
			></div>
		</section>
	);
}

export default Exhibition;