import {useState} from "react";
import {useTranslation} from "react-i18next";

import {HashLink} from "react-router-hash-link";
import NavLink from "./NavLink";

import {Button} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";

import SelectLang from "./SelectLang";
import languageData from "../../../data/languageData";

import logo from "../../../media/pic/ID-photo.png";
import "./Navbar.scss";

const Navbar = () =>
{
	const [menuOn, setMenuOn] = useState(false);
	const {t} = useTranslation();

	return (
		<nav>
			<div
				className={menuOn ? "nav-bar nav-bar-on" : "nav-bar"}
				onClick={() => setMenuOn(false)}
			>
				<HashLink smooth to="/#header">
					<div className="nav-header">
						<img
							src={logo}
							alt="ID logo"
							className="logo"
						></img>
						<h2>{t("name")}</h2>
					</div>
				</HashLink>

				<div className="link-bar">
					<NavLink id="about"/>
					<NavLink id="exhibition"/>
					<NavLink id="gallery"/>
					<NavLink id="contact"/>

					<SelectLang setMenuOn={setMenuOn} languageData={languageData}/>
				</div>
			</div>
			<Button
				id="toggle-btn"
				variant="text"
				size="small"
				sx={{
					fontSize: "40px",
				}}
				onClick={() => setMenuOn(prev => !prev)}
			>
				{menuOn ? (
					<MenuOpenIcon sx={{fontSize: "inherit"}}/>
				) : (
					<MenuIcon sx={{fontSize: "inherit"}}/>
				)}
			</Button>
		</nav>
	);
};

export default Navbar;
