import { useTranslation } from "react-i18next";
import profilePic from "../../../media/pic/about.jpg";
import "./About.scss";

const About = () => {
	const { t } = useTranslation();

	return (
		<section id="about">
			<div className="text-container">
				<h2>{t("about.title")}</h2>
				{t("about.content")
					.split("\n")
					.map(t => (
						<p key={t}>{t}</p>
					))
				}
			</div>
			<div
				className="img-container"
				style={{ backgroundImage: `url(${profilePic})`}}
			></div>
		</section>
	);
};

export default About;
