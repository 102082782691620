import { useTranslation } from "react-i18next";

import "./Header.scss";

const Header = () => {
	const { t } = useTranslation();

	return (
		<header id="header">
			<div className={"text-container"}>
				<h1>{t("name")}</h1>
				{t("header.description")
					.split("\n")
					.map(t => (
						<p key={t}>{t}</p>
					))
				}
			</div>
		</header>
	);
};

export default Header;
