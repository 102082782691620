import {Button} from "@mui/material";

import EmailIcon from "@mui/icons-material/Email";
import PhonelinkRingIcon from '@mui/icons-material/PhonelinkRing';
import ChatIcon from '@mui/icons-material/Chat';
import FacebookIcon from '@mui/icons-material/Facebook';

import "./Contact.scss";
import {useTranslation} from "react-i18next";
import GoogleMap from "../../components/GoogleMap";

const Contact = () =>
{
	const {t} = useTranslation();

	return (
		<section id="contact">
			<div className="contact-container">
				<h2>{t("contact.title")}</h2>
				<div className="contact-btn-container">
					{/*<Button*/}
					{/*	variant="contained"*/}
					{/*	color="secondary"*/}
					{/*	size="large"*/}
					{/*	startIcon={<PhonelinkRingIcon/>}*/}
					{/*	onClick={() =>*/}
					{/*		window.open(*/}
					{/*			`tel: ${t("contact.phone.value")}`,*/}
					{/*			"_blank"*/}
					{/*		)*/}
					{/*	}*/}
					{/*>*/}
					{/*	{t("contact.phone.value")}*/}
					{/*</Button>*/}
					<Button
						variant="contained"
						color="secondary"
						size="large"
						startIcon={<EmailIcon/>}
						onClick={() =>
							window.open(
								`mailto: ${t("contact.email.value")}`,
								"_blank"
							)
						}
					>
						{t("contact.email.value")}
					</Button>
					<Button
						variant="contained"
						color="secondary"
						size="large"
						startIcon={<ChatIcon/>}
						onClick={() =>
							window.open(
								`${t("contact.messenger.value")}`,
								"_blank",
							)
						}
					>
						{t("contact.messenger.label")}
					</Button>
					<Button
						variant="contained"
						color="secondary"
						size="large"
						startIcon={<FacebookIcon/>}
						onClick={() =>
							window.open(
								`${t("contact.facebook.value")}`,
								"_blank"
							)
						}
					>
						{t("contact.facebook.label")}
					</Button>
				</div>
			</div>
			<div className="map-container">
				<GoogleMap/>
			</div>
		</section>
	);
};

export default Contact;
